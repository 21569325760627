//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Wechat from "@/components/Billing/icons/wechat3.svg?inline";
export default {
  components: {
    Wechat
  },
  data() {
    return {
      timer: null,
      timerError: null,
      timerStatusError: null,
      error: false,
      qrCode: null,
      code: this.$route.params.code
    };
  },
  async created() {
    if (this.code) {
      var _this$qrCode;
      this.qrCode = await this.loadQR();
      if ((_this$qrCode = this.qrCode) !== null && _this$qrCode !== void 0 && _this$qrCode.success) {
        this.checkStatus();
      } else {
        this.goBack("error");
      }
    } else {
      this.goBack("error");
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearTimeout(this.timerError);
    clearTimeout(this.timerStatusError);
  },
  methods: {
    goBack(status) {
      this.$router.push({
        name: "ProfilePlan",
        query: {
          back: status
        }
      });
    },
    async loadQR() {
      try {
        return await this.$store.dispatch("billing/getQrCode", {
          code: this.code
        });
      } catch (err) {
        return false;
      }
    },
    checkStatus() {
      this.timer = setInterval(async () => {
        const {
          status
        } = await this.$store.dispatch("billing/getQrCodeStatus", {
          code: this.code
        });
        if (status != "no") {
          clearInterval(this.timer);
          this.goBack("success");
        }
      }, 5000);
      this.timerStatusError = setTimeout(() => {
        clearTimeout(this.timerStatusError);
        this.goBack("error");
      }, 360 * 1000);
    }
  }
};